import { mapActions, mapGetters } from 'vuex';
import dataLayerMixin from '@/plugins/dataLayer.mixin.js';

const Cookie = process.client ? require('js-cookie') : undefined;
const auth = {
  mixins: [dataLayerMixin],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      authStatus: 'auth/authStatus',
      stateError: 'auth/stateError',
      storeInformation: 'storeInformation',
    }),
  },
  methods: {
    ...mapActions({
      auth_request: 'auth/auth_request',
      getFavorites: 'favorites/getFavorites',
      getCreditsAllowances: 'creditsAllowances/getCreditsAllowances',
    }),
    async logIn(user, skipRedirect) {
      try {
        const latestVisit = localStorage.getItem('latestVisit');
        const redirectUrl = this.$route.query.redirect;
        this.error = '';
        this.loading = true;
        const { email, password, redirect, storeUrl } = user;
        const customer = await this.auth_request({ email, password, storeUrl });
        if (this.authStatus === 'error') {
          this.error = this.stateError;
        }
        if (!this.error) {
          this.onDataLayerLogin(customer);

          await this.getFavorites();
          await this.getCreditsAllowances();
          let cartOrders = Cookie.get('cartOrders') || undefined;
          cartOrders = cartOrders ? JSON.parse(cartOrders) : [];
          if (cartOrders.length) {
            await this.$store.dispatch('cart/clear', {});
            Cookie.remove('cartOrders');
            try {
              await Promise.all(
                cartOrders.map((order) => {
                  const orderId = order._id;
                  return this.$axios.put(`/orders/${orderId}/mergecart`, {
                    id: orderId,
                    cartType: order.type,
                    customerId: customer._id,
                    action: 2,
                  });
                })
              );
            } catch (e) { }
            await this.$store.dispatch('cart/loadOrders', {
              customerId: customer._id,
              storeInformation: this.storeInformation,
              forceLoad: true,
            });
          }
          localStorage.removeItem('latestVisit');
          if (skipRedirect) {
            // skip redirect
          } else if (redirectUrl) {
            this.$router.push(redirectUrl);
          } else if (redirect) {
            this.$router.push(redirect);
          } else if (latestVisit) {
            this.$router.push(JSON.parse(latestVisit));
            localStorage.removeItem('latestVisit');
          } else if (this.prevRoute && this.prevRoute.path) this.$router.push(this.prevRoute.path);
          else this.$router.push('/account');
        }
      } catch (err) {
        this.error = err.message || err;
      } finally {
        this.loading = false;
      }
    },
  },
};
export { auth as default };
